import {
  expectSuccess,
  expectSuccessOrInvalidRequestErrors,
  expectSuccessWithJsonBody,
  expectSuccessWithJsonBodyOrInvalidRequestErrors,
  forkAsPromise,
  iIndexBy,
  safeHead,
} from "common/utils/secure-ui/utils/src";
import Maybe from "data.maybe";
import Task from "data.task";
import { OrderedMap, Record, Seq } from "immutable";
import PropTypes from "prop-types";
import { always, applySpec, compose, curry, prop } from "ramda";
import { useCallback, useMemo } from "react";
import ImmutablePropTypes from "react-immutable-proptypes";
import { createSimpleResource } from "react-remote-resource";
import { safeToInt } from "utils";
import { vkEndpoint } from "utils/endpoints";
import { sanitizePhone } from "utils/string";
import { maybePropType } from "../utils/prop-types";
import {
  provideAuthTokenForCustomer,
  useAuthTokenForActiveCustomer,
} from "./auth-token";
import { provideCustomerId, useCustomerId } from "./user";
import { createJson, deleteJson, getJson, patchJson } from "./utils/json";

// ---------------------------
// Resource Models
// ---------------------------

export const Contact = Record({
  id: null,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  userId: Maybe.Nothing(),
  unsubscriptions: [],
});

export const ContactValidationErrors = Record({
  firstName: Maybe.Nothing(),
  lastName: Maybe.Nothing(),
  email: Maybe.Nothing(),
  phoneNumber: Maybe.Nothing(),
});

export const ContactPropType = ImmutablePropTypes.recordOf({
  id: PropTypes.number,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  userId: maybePropType(PropTypes.number),
});

// ---------------------------
// Resource Helpers
// ---------------------------

const normalizeContact = (contact) =>
  Contact({
    id: contact.id,
    firstName: contact.first_name,
    lastName: contact.last_name,
    email: contact.email || "",
    phoneNumber: contact.phone_number || "",
    userId: Maybe.fromNullable(contact.user_id).chain(safeToInt),
    unsubscriptions: contact.unsubscriptions.map(
      applySpec({
        phoneNumber: prop("phone_number"),
        orderer: prop("orderer"),
      })
    ),
  });

const contactToJson = (contact) => ({
  id: contact.id,
  first_name: contact.firstName,
  last_name: contact.lastName,
  email: contact.email,
  phone_number: sanitizePhone(contact.phoneNumber),
  user_id: contact.userId.getOrElse(null),
});

const contactsFromJson = (json) =>
  Seq(json)
    .map(prop("contact"))
    .map(normalizeContact)
    .sortBy((contact) => `${contact.firstName} ${contact.lastName}`)
    .reduce(iIndexBy(prop("id")), OrderedMap());

const getCustomerContacts = (authToken, customerId, page = 1, pageSize = 100) =>
  expectSuccessWithJsonBody(
    (json) =>
      Array.isArray(json) ? Maybe.of(contactsFromJson(json)) : Maybe.Nothing(),
    getJson({
      url: vkEndpoint(`/v2/customers/${customerId}/contacts`),
      authToken,
      params: {
        page,
        page_size: pageSize,
      },
    })
  ).chain((contacts) =>
    contacts.count() === pageSize
      ? getCustomerContacts(authToken, customerId, page + 1, pageSize).map(
          (nextContacts) => contacts.merge(nextContacts)
        )
      : Task.of(contacts)
  );

const normalizeValidationErrors = (errors) =>
  ContactValidationErrors({
    firstName: Maybe.fromNullable(errors.first_name).chain(safeHead),
    lastName: Maybe.fromNullable(errors.last_name).chain(safeHead),
    email: Maybe.fromNullable(errors.email).chain(safeHead),
    phoneNumber: Maybe.fromNullable(errors.phone_number).chain(safeHead),
  });

export const updateContact = curry((authToken, customerId, contact) =>
  expectSuccessOrInvalidRequestErrors(
    ({ errors }) => Maybe.fromNullable(errors).map(normalizeValidationErrors),
    patchJson({
      url: vkEndpoint(`/v2/customers/${customerId}/contacts/${contact.id}`),
      authToken,
      body: {
        contact: contactToJson(contact),
      },
    })
  ).map(always(contact))
);

const createContact = curry((authToken, customerId, contact) =>
  expectSuccessWithJsonBodyOrInvalidRequestErrors(
    ({ errors }) => Maybe.fromNullable(errors).map(normalizeValidationErrors),
    ({ contact }) => Maybe.fromNullable(contact).map(normalizeContact),
    createJson({
      url: vkEndpoint(`/v2/customers/${customerId}/contacts`),
      authToken,
      body: {
        contact: contactToJson(contact),
      },
    })
  )
);

const deleteContact = curry((authToken, customerId, contact) =>
  expectSuccess(
    deleteJson({
      url: vkEndpoint(`/v2/customers/${customerId}/contacts/${contact.id}`),
      authToken,
    })
  ).map(always(contact))
);

// ---------------------------
// Resource
// ---------------------------

const contactsResource = provideCustomerId(
  provideAuthTokenForCustomer(
    createSimpleResource(compose(forkAsPromise, getCustomerContacts))
  )
);

export default contactsResource;

// ---------------------------
// Related Hooks
// ---------------------------

export const useContacts = () => {
  return contactsResource.useState();
};

export const useContact = (id) => {
  const [contacts] = useContacts();
  return useMemo(() => Maybe.fromNullable(contacts.get(id)), [id, contacts]);
};

export const useCreateContact = () => {
  const authToken = useAuthTokenForActiveCustomer();
  const customerId = useCustomerId();

  return useCallback(createContact(authToken, customerId), [
    authToken,
    customerId,
  ]);
};

export const useUpdateContact = () => {
  const authToken = useAuthTokenForActiveCustomer();
  const customerId = useCustomerId();

  return useCallback(updateContact(authToken, customerId), [
    authToken,
    customerId,
  ]);
};

export const useDeleteContact = () => {
  const authToken = useAuthTokenForActiveCustomer();
  const customerId = useCustomerId();

  return useCallback(deleteContact(authToken, customerId), [
    authToken,
    customerId,
  ]);
};
