/**
 * @generated SignedSource<<641ef1e7f2ce0f81d77c7b761914da98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ServicesManagerDoorAccessLevel = "ALL" | "DENIALS_ONLY" | "NONE";
import { FragmentRefs } from "relay-runtime";
export type controlSystemServicesModel_servicesManager$data = {
  readonly armingAppEnabled: boolean;
  readonly digitalWatchdogSpectrumEnabled: boolean;
  readonly dmpVideoEnabled: boolean;
  readonly doorAccessLevel: ServicesManagerDoorAccessLevel;
  readonly doorsEditingEnabled: boolean;
  readonly eagleEyeEnabled: boolean;
  readonly favoriteEditingEnabled: boolean;
  readonly fullAppEnabled: boolean;
  readonly hikvisionNvrEnabled: boolean;
  readonly iensoEnabled: boolean | null;
  readonly lightControlEnabled: boolean;
  readonly lockControlEnabled: boolean;
  readonly openEyeEnabled: boolean;
  readonly outputOptionsEditingEnabled: boolean;
  readonly realTimeEventsEnabled: boolean;
  readonly ringDoorbellEnabled: boolean;
  readonly scheduleEditEnabled: boolean;
  readonly sensorActivityEnabled: boolean;
  readonly storeUserCodes: boolean;
  readonly thermostatControlEnabled: boolean;
  readonly trafficCountEnabled: boolean;
  readonly userCodeEditingEnabled: boolean;
  readonly varEnabled: boolean | null;
  readonly zwaveNodeEditingEnabled: boolean;
  readonly " $fragmentType": "controlSystemServicesModel_servicesManager";
};
export type controlSystemServicesModel_servicesManager$key = {
  readonly " $data"?: controlSystemServicesModel_servicesManager$data;
  readonly " $fragmentSpreads": FragmentRefs<"controlSystemServicesModel_servicesManager">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "controlSystemServicesModel_servicesManager"
};

(node as any).hash = "fed3b568297661e7fa2fb563ea6a6e60";

export default node;
